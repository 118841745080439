@media (max-width:700px) {
    .MuiModal-root .MuiBox-root:not(.small-modal) {
      /* position: fixed;
      width: 100%;
      max-width: 100vw;
      max-height: 100%;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      overflow-y: auto; */
    }

    .MuiModal-root .MuiBox-root:not(.small-modal) {
      max-width: 650px;
      width: 70%;
    }

    .MuiModal-root .MuiBox-root:not(.small-modal) .MuiTableContainer-root .MuiTable-root {
      max-width: 650px;
      width: 100%;
    }
  }
  
  @media (max-height:1000px) {
    .MuiModal-root .MuiBox-root:not(.small-modal) {
      max-height: 50%;
      transform: translate(-50%, -50%);
      overflow-y: auto;
    }
  }

  @media (min-width:700px) {
    .MuiModal-root .MuiBox-root:not(.small-modal) {
      max-width: 650px;
      width: 70%;
    }

    .MuiModal-root .MuiBox-root:not(.small-modal) .MuiTableContainer-root .MuiTable-root {
      max-width: 650px;
      width: 100%;
    }
  }
  
  
  