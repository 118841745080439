.searchType {
    padding-top: 2rem;
    padding-bottom: 1rem;
    color: #212121
}

@media (max-width:480px) {
    .searchType {
        padding-top: 1rem;
    }
}

